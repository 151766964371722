@font-face {
    font-family: 'Fixer';
    src: local('Fixer Regular'), local('Fixer-Regular'),
        url("src/assets/fonts/Fixer/Fixer Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fixer Display Fill';
    src: local('Fixer Display Fill'), local('Fixer-Display Fill'),
        url("src/assets/fonts/Fixer/Fixer Display Fill.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fixer Outline';
    src: local('Fixer Outline'), local('Fixer-Outline'),
        url("src/assets/fonts/Fixer/Fixer Outline.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
