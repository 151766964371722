@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Italic'), local('CeraPro-Italic'),
        url(src/assets/fonts/CeraPro/CeraPro-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Regular'), local('CeraPro-Regular'),
        url(src/assets/fonts/CeraPro/CeraPro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
        url(src/assets/fonts/CeraPro/CeraPro-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Light'), local('CeraPro-Light'),
        url(src/assets/fonts/CeraPro/CeraPro-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
        url(src/assets/fonts/CeraPro/CeraPro-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Black'), local('CeraPro-Black'),
        url(src/assets/fonts/CeraPro/CeraPro-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Medium'), local('CeraPro-Medium'),
        url(src/assets/fonts/CeraPro/CeraPro-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Bold'), local('CeraPro-Bold'),
        url(src/assets/fonts/CeraPro/CeraPro-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Thin'), local('CeraPro-Thin'),
        url(src/assets/fonts/CeraPro/CeraPro-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
        url(src/assets/fonts/CeraPro/CeraPro-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
        url(src/assets/fonts/CeraPro/CeraPro-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;

}

@font-face {
    font-family: 'Cera Pro';
    src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
        url(src/assets/fonts/CeraPro/CeraPro-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;

}

